import { FIELDS } from '../../constants/roles'
import { IController } from '../controllers/controllers'
import { submitUtils } from '../submit-utils'

export const addRegistrationFormValidation = (controller: IController) => {
  const REQUIRED_REGISTRATION_TEXT_ROLES = [
    FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL,
    FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD,
  ]

  const requiredInputTextFields = controller.getFieldsByRoles(REQUIRED_REGISTRATION_TEXT_ROLES)

  requiredInputTextFields.forEach(field => {
    field.required = true
    field.resetValidityIndication()
  })
}
